import { z } from 'zod';

import { Setup } from '../../constants/messages';
import { StepBoxReveal, StepCardsReveal, StepIdle, StepPackReveal } from '../../constants/steps';
import { BoxSchema } from '../Box';
import { UpdateCardDataSchema } from './UpdateCard';

// SetupData - also used in Restart message
export const SetupSchema = z.object({
    type: z.literal(Setup),
    data: z.object({
        box: BoxSchema,
        step: z.enum([StepIdle, StepBoxReveal, StepPackReveal, StepCardsReveal]),
        rerip: z.boolean(),
        card: UpdateCardDataSchema,
    }),
});
export type SetupMessage = z.infer<typeof SetupSchema>;
