import { z } from 'zod';

import { UpdateStep } from '../../constants/messages';
import { StepBoxReveal, StepCardsReveal, StepIdle, StepPackReveal } from '../../constants/steps';

// UpdateStep schema
export const UpdateStepSchema = z.object({
    type: z.literal(UpdateStep),
    data: z.enum([StepIdle, StepBoxReveal, StepPackReveal, StepCardsReveal]),
});
export type UpdateStepMessage = z.infer<typeof UpdateStepSchema>;
