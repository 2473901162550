import { z } from 'zod';

import { CardSchema } from './Card';

export const PackSchema = z.object({
    color: z.string(),
    image: z.string().url(),
    username: z.string().optional(),
    cards: z.array(CardSchema),
});

export type Pack = z.infer<typeof PackSchema>;
