import { z } from 'zod';

import { PackSchema } from './Pack';

export const BoxSchema = z.object({
    color: z.string(),
    image: z.string().url(),
    pack: PackSchema,
});

export type Box = z.infer<typeof BoxSchema>;
