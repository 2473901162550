import { z } from 'zod';

export const CutawayCSchema = z.object({
    type: z.literal('cutaway-c'),
    data: z.object({
        subtitle: z.string().max(35).optional(),
        title: z.array(z.string().max(10)).max(2),
        caption: z.string().max(50).optional(),
    }),
});
export type CutawayC = z.infer<typeof CutawayCSchema>;
