import { z } from 'zod';

import { StepBoxReveal, StepCardsReveal, StepIdle, StepPackReveal } from '../constants/steps';
import { BoxSchema } from './Box';

export const BREAK_STEPS = [
    StepIdle, // Idle state, before box break starts
    StepBoxReveal, // Signal to start open box animation, revealing the pack
    StepPackReveal, // Signal to start open pack animation, revealing the cards
    StepCardsReveal, // Cards are revealed and ready to be shown
] as const;

export const BreakStepsSchema = z.enum(BREAK_STEPS);

export type BreakSteps = z.infer<typeof BreakStepsSchema>;

export const BreakSchema = z.object({
    id: z.string(),
    currentStep: BreakStepsSchema,
    box: BoxSchema,
});

export type Break = z.infer<typeof BreakSchema>;
