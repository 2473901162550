import { z } from 'zod';

import { UpdateCard } from '../../constants/messages';

export const UpdateCardDataSchema = z.object({
    id: z.string(),
    index: z.number(),
    active: z.boolean(),
    revealed: z.boolean(),
    rotation: z.number(),
    flip: z.boolean(),
    zoom: z.boolean(),
    revealProgress: z.number(),

    meta: z.object({}).optional(),
});

export const UpdateCardSchema = z.object({
    type: z.literal(UpdateCard),
    data: UpdateCardDataSchema,
});

export type UpdateCardMessage = z.infer<typeof UpdateCardSchema>;
export type UpdateCardDataMessage = z.infer<typeof UpdateCardDataSchema>;
