import { z } from 'zod';

import { TIERS } from '../constants/tiers';
import { CardInfoSchema } from './reveals/CardInfo';
import { CutawayASchema } from './reveals/CutawayA';
import { CutawayBSchema } from './reveals/CutawayB';
import { CutawayCSchema } from './reveals/CutawayC';
import { PlayerInfoSchema } from './reveals/PlayerInfo';
import { TeamInfoSchema } from './reveals/TeamInfo';

export const CardSchema = z.object({
    // Card info
    id: z.string(),
    title: z.string(),
    condition: z.string(),
    serialNumber: z.number(),
    serialTotal: z.number(), // total number of cards in the set (serialNumber / serialTotal)
    imageFront: z.string().url(), // front of the card
    imageBack: z.string().url(), // back of the card
    gradeBadge: z.string().url(), // grade badge: needs to be transparent png
    preRevealSticker: z.string().url().optional(),
    parallel: z.string(),
    tier: TIERS,
    grader: z.union([z.literal('psa'), z.literal('bgs'), z.literal('cgc')]).optional(),

    // Player
    playerName: z.object({
        first: z.string(),
        last: z.string(),
    }),

    playerNumber: z.number(),

    // Team
    teamName: z.object({
        line1: z.string(),
        line2: z.string(),
    }),

    teamBadge: z.string().url(),
    teamYear: z.number(),

    // List of reveals
    reveals: z.array(
        z.union([
            TeamInfoSchema,
            PlayerInfoSchema,
            CardInfoSchema,
            CutawayASchema,
            CutawayBSchema,
            CutawayCSchema,
        ])
    ),

    color: z.string().optional(), // For testing purposes
});

export type Card = z.infer<typeof CardSchema>;
